
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Resize,
  Sort,
  ContextMenu,
  Filter,
  Page,
  ExcelExport,
  PdfExport,
  Inject,
  Toolbar,
  Edit,
  Search
} from '@syncfusion/ej2-react-grids';
import {  feedbackGrid } from '../data/dummy';
const Feedback = () => {
    const navigateTo=useNavigate();
    const toolbarOptions = ['ExcelExport'];
    const [feedback, setFeedback] = useState([]);

    const toolbarClick = (args) => {
        if (args.item.id === 'gridcomp_excelexport') {
          document.getElementById('gridcomp').ej2_instances[0].excelExport();
        } else if (args.item.id === 'gridcomp_pdfexport') {
          document.getElementById('gridcomp').ej2_instances[0].pdfExport();
        }
      };

      useEffect(() => {
        axios
          .get('https://gowinadminback.433sportethiopia.com/feedbacks')
          .then((response) => {

            console.log('Data received from backend:', response.data); 
            const formattedData = response.data.map((row) => {
                const date = new Date(row.date);
                const formattedDate = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
                return {
                    fid:row.id,
                  id:row.user_id,
                  name: row.customer_name,
                  phone: row.customer_phone,
                  feedback: row.feedback,
                  date:formattedDate,
                  response:row.response
       
                };
              });
      
              setFeedback(formattedData);
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
          });
      }, []);

      
      const sendResponseToCustomer = (args) => {
        const selectedRowData = args.data;
        console.log(selectedRowData);
    
        const params = {
            fid:selectedRowData.fid,
          userid:selectedRowData.id,
          name:selectedRowData.name,
          phone:selectedRowData.phone,
          feedback:selectedRowData.feedback,
          date:selectedRowData.date,
          response:selectedRowData.response

  
    
        };
    
        navigateTo('/feedresponse', { state: params });
      };


    return (
        <div>

          <GridComponent
               id="gridcomp"
      dataSource={feedback}
      allowPaging
      allowSorting
      allowResize
      allowFiltering
      editSettings={{  mode: 'Normal', showDeleteConfirmDialog: false }}
      pageSettings={{ pageSize: 10 }}
      allowExcelExport="true"
      toolbar={toolbarOptions}
      toolbarClick={toolbarClick}
      height="500"
      width="100%"
      allowTextWrap={true} 
      allowTextWrapSettings={{ wrapMode: 'Content' }}
      allowResizing={true}
      rowSelected={sendResponseToCustomer}

    
    >
      
            <ColumnsDirective>
            
         
              {feedbackGrid.map((item, index) => (
                <ColumnDirective key={index} {...item} />
              ))}
    
            </ColumnsDirective>
            <Inject services={[Resize, Sort, ContextMenu, Filter, Page, Toolbar, ExcelExport, PdfExport,Edit,Search]} />
          </GridComponent>
          </div>
      );
}

export default Feedback