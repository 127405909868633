import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Resize,
  Sort,
  ContextMenu,
  Filter,
  Page,
  ExcelExport,
  PdfExport,
  Inject,
  Toolbar,
} from '@syncfusion/ej2-react-grids';
import { categoriesGrid } from '../data/dummy';

const GameCategories = () => {
  const [categories, setCategories] = useState([]);
  const navigateTo = useNavigate();
  const toolbarOptions = ['ExcelExport'];

  const toolbarClick = (args) => {
    if (args.item.id === 'gridcomp_excelexport') {
      document.getElementById('gridcomp').ej2_instances[0].excelExport();
    } else if (args.item.id === 'gridcomp_pdfexport') {
      document.getElementById('gridcomp').ej2_instances[0].pdfExport();
    }
  };
  const handleAddNewCategories = () => {
    // Add your logic here to navigate or perform any action on button click
    console.log('Add New Category clicked');
    navigateTo('/addCategories'); // Navigating to the "/here" page
  };
  const handleDeleteCategory = (args) => {
    const selectedRowData = args.data;
    const target = args.target;
  
    // Check if args.data is defined and if the clicked element is the delete button
    if (selectedRowData && target.classList.contains('e-delete')) {
      // Make an API call to delete the category using the relations ID
      axios
        .delete(`https://gowinadminback.433sportethiopia.com/deleteCategory/${selectedRowData.relationsId}`)
        .then((response) => {
          console.log('Category deleted:', response.data);
  
          // After deletion, update the table by fetching the updated data
          axios
            .get('https://gowinadminback.433sportethiopia.com/availableCategories')
            .then((response) => {
                console.log("hello")
                console.log(response)
              const formattedData = response.data.map((row) => {
                return {
                  relationsId: row.relations_id,
                  sportsType: row.sport_name,
                  country: row.country_name,
                  category: row.category_name,
                };
              });
  
              setCategories(formattedData);
            })
            .catch((error) => {
              console.error('Error fetching updated data:', error);
            });
        })
        .catch((error) => {
          console.error('Error deleting category:', error);
        });
    }
  };
  

  useEffect(() => {
    axios
      .get('https://gowinadminback.433sportethiopia.com/availableCategories')
      .then((response) => {
        const formattedData = response.data.map((row) => {
  
            return {
              relationsId: row.relations_id,
              sportsType: row.sport_name,
              country: row.country_name,
              category: row.category_name,
            };
          });
  
          setCategories(formattedData);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

 
  return (
    <div className="m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl" style={{ zIndex: 90 }}>
      <div className="flex justify-end mb-4">
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          onClick={handleAddNewCategories}
        >
            Add New Category
        </button>
      </div>
      <GridComponent
        id="gridcomp"
        dataSource={categories}
        allowPaging
        allowSorting
        allowResize
        allowFiltering
        pageSettings={{ pageSize: 10 }}
        allowExcelExport="true"
        toolbar={toolbarOptions}
        toolbarClick={toolbarClick}
        height="500"
        rowSelected={(args) => handleDeleteCategory(args)}

      >
        <ColumnsDirective>
        <ColumnDirective
          headerText=""
          template={() => (
            <button className="e-tbar-btn e-btn" title="Delete" onClick={handleDeleteCategory}>
              <span className="e-btn-icon e-icons e-delete"></span>
            </button>
          )}
          width="80"
        />
          {categoriesGrid.map((item, index) => (
            <ColumnDirective key={index} {...item} />
          ))}
        </ColumnsDirective>
        <Inject services={[Resize, Sort, ContextMenu, Filter, Page, Toolbar, ExcelExport, PdfExport]} />
      </GridComponent>
    </div>
  );
};

export default GameCategories;
