import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Resize,
  Sort,
  ContextMenu,
  Filter,
  Page,
  ExcelExport,
  PdfExport,
  Inject,
  Toolbar,
} from '@syncfusion/ej2-react-grids';
import { currentBidsGrid } from '../data/dummy';

const CurrentBids = () => {
  const [bids, setBid] = useState([]);
  const navigateTo = useNavigate();
  const toolbarOptions = ['ExcelExport'];

  const toolbarClick = (args) => {
    if (args.item.id === 'gridcomp_excelexport') {
      document.getElementById('gridcomp').ej2_instances[0].excelExport();
    } else if (args.item.id === 'gridcomp_pdfexport') {
      document.getElementById('gridcomp').ej2_instances[0].pdfExport();
    }
  };

  useEffect(() => {
    axios
      .get('https://gowinadminback.433sportethiopia.com/currentbids')
      .then((response) => {
        const formattedData = response.data.map((row) => {
          const date = new Date(row.date);
          const formattedDate = date.toISOString().split('T')[0]; // Extracting only the date part

          return {
            biddername: row.name,
            bidderphonenum: row.phone_num,
            gameon: row.game_odd,
            status: row.status,
            amount: row.amount,
            date: formattedDate,
            bidId:row.bid_id,
          };
        });

        setBid(formattedData);
        console.log(formattedData);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);


  const showBidDetails = (args) => {
    const selectedRowData = args.data;
    const imageUrl = selectedRowData.Image;

    const params = {
      bidID: selectedRowData.bidId,
      bidderName: selectedRowData.biddername,
      bidderPhone: selectedRowData.bidderphonenum,
      gameOn: selectedRowData.gameon,
      status: selectedRowData.status,
      amount: selectedRowData.amount,
      date: selectedRowData.date,
      image:imageUrl,

    };

    navigateTo('/bidsDetail', { state: params });
  };

  return (
    <div className="m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl" style={{ zIndex: 90 }}>
      <GridComponent
        id="gridcomp"
        dataSource={bids}
        allowPaging
        allowSorting
        allowResize
        allowFiltering
        pageSettings={{ pageSize: 10 }}
        // rowSelected={showBidDetails}
        allowExcelExport="true"
        toolbar={toolbarOptions}
        toolbarClick={toolbarClick}
        height="500"
      >
        <ColumnsDirective>
          {currentBidsGrid.map((item, index) => (
            <ColumnDirective key={index} {...item} />
          ))}

        </ColumnsDirective>
        <Inject services={[Resize, Sort, ContextMenu, Filter, Page, Toolbar, ExcelExport, PdfExport]} />
      </GridComponent>
    </div>
  );
};

export default CurrentBids;
