import React from 'react';
import logo from '../data/logo.jpg';

const Header = () => {
  return (
    <header className="bg-gray-800 text-white p-4 md:p-6 flex justify-between items-center w-full">
      <div className="flex items-center">
        <img
          src={logo}
          alt="Logo"
          className="mr-2 w-10 h-10 md:w-12 md:h-12 rounded-full"
        />
        <div className="text-xl font-bold">Go Win</div>
      </div>

    </header>
  );
};

export default Header;
