

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Resize,
  Sort,
  ContextMenu,
  Filter,
  Page,
  ExcelExport,
  PdfExport,
  Inject,
  Toolbar,
  Edit
} from '@syncfusion/ej2-react-grids';
import {  transactionGrid } from '../data/dummy';

const Transaction = () => {
  const [game, setGame] = useState([]);
  const navigateTo = useNavigate();
  const toolbarOptions = ['ExcelExport'];
const BOT_TOKEN='6717614392:AAF1lKDz_uHkQskJ3ezhsbtMiRP2inE-tfI'
  const toolbarClick = (args) => {
    if (args.item.id === 'gridcomp_excelexport') {
      document.getElementById('gridcomp').ej2_instances[0].excelExport();
    } else if (args.item.id === 'gridcomp_pdfexport') {
      document.getElementById('gridcomp').ej2_instances[0].pdfExport();
    }
  };
  const ImageTemplate = ({ Image }) => {
    const [imageUrl, setImageUrl] = useState('');

    
    useEffect(() => {
      fetch(`https://api.telegram.org/bot${BOT_TOKEN}/getFile?file_id=${Image}`)
        .then(response => response.json())
        .then(data => {
          const filePath = data.result.file_path;
          console.log(filePath)
          if (filePath==='null'){

              const imageUrl = `https://api.telegram.org/file/bot${BOT_TOKEN}/AgACAgQAAxkBAAIB_WWtJzxK6S0KX4TQHXrUkTThHUNFAAK2wDEbndJpUTBxVdCz9qJoAQADAgADbQADNAQ`;
              setImageUrl(imageUrl);
          }
          else{
            const imageUrl = `https://api.telegram.org/file/bot${BOT_TOKEN}/${filePath}`;
              setImageUrl(imageUrl);
          }
        })
        .catch(error => {
            setImageUrl('')
          console.error('Error fetching image data:', error);
        });
    }, [Image]);

    return <img src={imageUrl} alt="" width="180" className='h-20' />;
  };

  const showTransactionDetails = (args) => {
    const selectedRowData = args.data;
    console.log(selectedRowData)
    const imageUrl = selectedRowData.Image;
    const params = {

      image:imageUrl,
      status:selectedRowData.status,
      tid:selectedRowData.transaction_id,
      userid:selectedRowData.user_id,
      amount:selectedRowData.amount,
      type:selectedRowData.type,
      bank:selectedRowData.bank,

    };

    navigateTo('/transactionDetails', { state: params });
  };
  
  const handleActionComplete = (args) => {
    if (args.requestType === 'save') {
      const updatedData = args.data;
      console.log(args.data)

      const isConfirmed = window.confirm('Do you want to save changes?');

      if (isConfirmed) {
        axios
          .put(`https://gowinadminback.433sportethiopia.com/updateTransaction/${updatedData.transaction_id}`, updatedData)
          .then((response) => {
            console.log('Game updated:', response.data);
          })
          .catch((error) => {
            console.error('Error updating game:', error);
            alert('Error updating game. Please try again.'); // Notify the user about the error
          });
      }
    }
  };
  

  useEffect(() => {
    axios
      .get('https://gowinadminback.433sportethiopia.com/transactions')
      .then((response) => {
        const formattedData = response.data.map((row) => {
          const date = new Date(row.date);
          const timeZoneOffsetInMinutes = date.getTimezoneOffset();
          const adjustedMatchDate = new Date(date.getTime() - timeZoneOffsetInMinutes * 60 * 1000);

          const formattedMatchDate = adjustedMatchDate.toISOString().split('T')[0];
          return {
            type: row.type,
            status: row.status,
            user: row.user_name,
            phonenum:row.user_phone,
            amount: row.amount,
            date: formattedMatchDate,
            transaction_id:row.transaction_id,
            user_id:row.user_id,
            bank:row.bank_name,
            accnum:row.accnum||'-',
            Image: row.screenshot || 'AgACAgQAAxkBAAIB_WWtJzxK6S0KX4TQHXrUkTThHUNFAAK2wDEbndJpUTBxVdCz9qJoAQADAgADbQADNAQ', 
          };
        });

        setGame(formattedData);
        // console.log(formattedData);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);





  return (

      <GridComponent
  id="gridcomp"
  actionComplete={(args) => handleActionComplete(args)}
  dataSource={game}
  allowPaging
  allowSorting
  allowResize
  allowFiltering
  editSettings={{  mode: 'Normal', showDeleteConfirmDialog: false }}
  pageSettings={{ pageSize: 20 }}
  allowExcelExport="true"
  toolbar={toolbarOptions}
  toolbarClick={toolbarClick}
  height="500"
  rowSelected={showTransactionDetails}

>
        <ColumnsDirective>
        
     
          {transactionGrid.map((item, index) => (
            <ColumnDirective key={index} {...item} />
          ))}
            <ColumnDirective headerText="Image" width="90" textAlign="Center" template={ImageTemplate} />

        </ColumnsDirective>
        <Inject services={[Resize, Sort, ContextMenu, Filter, Page, Toolbar, ExcelExport, PdfExport,Edit]} />
      </GridComponent>
  );
};

export default Transaction;
