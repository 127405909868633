import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from 'react-router-dom';

const AddGames = () => {
  const [sports, setSports] = useState([]);
  const [countries, setCountries] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedSport, setSelectedSport] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [homeTeam, setHomeTeam] = useState('');
  const [homeOdd, setHomeOdd] = useState('');
  const [awayTeam, setAwayTeam] = useState('');
  const [awayOdd, setAwayOdd] = useState('');
  const [drawOdd, setDrawOdd] = useState('');
  const [matchDate, setMatchDate] = useState(new Date());
  const [matchTime, setMatchTime] = useState(new Date());
  const [statusOptions] = useState(['open', 'closed', 'pending']);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedTimeOfDay, setSelectedTimeOfDay] = useState('');
  const [isBetting, setIsBetting] = useState(false);
  const [isCorrectScore, setIsCorrectScore] = useState(false);

  const handleBettingChange = () => {
    setIsBetting((prevBetting) => {
      console.log("Betting status:", !prevBetting);
      return !prevBetting;
    });
  };
  
  const handleCorrectScoreChange = () => {
    setIsCorrectScore((prevCorrectScore) => {
      console.log("Correct Score status:", !prevCorrectScore);
      return !prevCorrectScore;
    });
  };
  

  const navigateTo = useNavigate();

  useEffect(() => {
    axios.get('https://gowinadminback.433sportethiopia.com/sportsList')
      .then((response) => setSports(response.data))
      .catch((error) => console.error('Error fetching sports:', error));
  }, []);

  useEffect(() => {
    if (selectedSport) {
      axios.get(`https://gowinadminback.433sportethiopia.com/countriesList/${selectedSport.value}`)
        .then((response) => setCountries(response.data))
        .catch((error) => console.error('Error fetching countries:', error));
    }
  }, [selectedSport]);

  useEffect(() => {
    if (selectedSport && selectedCountry) {
      axios.get(`https://gowinadminback.433sportethiopia.com/categoriesList/${selectedSport.value}/${selectedCountry.value}`)
        .then((response) => setCategories(response.data))
        .catch((error) => console.error('Error fetching categories:', error));
    }
  }, [selectedSport, selectedCountry]);

  // Reset selectedCategory, homeTeam, awayTeam, homeOdd, awayOdd, drawOdd when selectedSport changes
  useEffect(() => {
    setSelectedCategory(null);
    setHomeTeam('');
    setAwayTeam('');
    setHomeOdd('');
    setAwayOdd('');
    setDrawOdd('');
  }, [selectedSport]);

  // Reset homeTeam, awayTeam, homeOdd, awayOdd, drawOdd when selectedCountry changes
  useEffect(() => {
    setHomeTeam('');
    setAwayTeam('');
    setHomeOdd('');
    setAwayOdd('');
    setDrawOdd('');
  }, [selectedCountry]);

  // Reset selectedCountry when selectedSport changes
  useEffect(() => {
    setSelectedCountry(null);
  }, [selectedSport]);

  // Reset selectedStatus when selectedSport changes
  useEffect(() => {
    setSelectedStatus('');
  }, [selectedSport]);
  const generateTimeOptions = () => {
    const times = [];
    for (let hour = 0; hour < 12; hour++) {
      for (let minute = 0; minute < 60; minute += 10) {
        const time = new Date(0, 0, 0, hour, minute);
        times.push(time);
      }
    }
    return times;
  };


  const handleAddGame = () => {
    if (!selectedSport || !selectedCountry || !selectedCategory || !homeTeam || !awayTeam || !selectedStatus) {
      alert("Please fill in all required fields");
      return;
    }
    const formattedTime = `${matchTime.getHours()}:${matchTime.getMinutes() < 10 ? '0' : ''}${matchTime.getMinutes()}`;
    const selectedTime = `${selectedTimeOfDay} ${formattedTime} `;
    console.log(selectedTime)
    const newGame = {
      sports_id: selectedSport.value,
      country_id: selectedCountry.value,
      category_id: selectedCategory.value,
      home: homeTeam,
      away: awayTeam,
      matchdate: matchDate.toISOString().split('T')[0],
      matchtime:selectedTime,
      status: selectedStatus.value,
      betting: isBetting ? 1 : 0, 
      correctscore: isCorrectScore ? 1 : 0,
    };
console.log(newGame)
    axios.post('https://gowinadminback.433sportethiopia.com/addGame', newGame)
      .then((response) => {
        console.log('Game added successfully:', response.data);
        navigateTo('/games');
      })
      .catch((error) => {
        console.error('Error adding game:', error.response.data.message);
      });
  };

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Add Game</h1>

      <div className="mb-4">
        <label className="mr-2">Sport Type:</label>
        <Select
          options={sports.map((sport) => ({ value: sport.sport_id, label: sport.sport_name }))}
          value={selectedSport}
          onChange={(selectedOption) => setSelectedSport(selectedOption)}
        />
      </div>

      {/* Country Dropdown */}
      <div className="mb-4">
        <label className="mr-2">Country:</label>
        <Select
          options={countries.map((country) => ({ value: country.country_id, label: country.country_name }))}
          value={selectedCountry}
          onChange={(selectedOption) => setSelectedCountry(selectedOption)}
        />
      </div>

      {/* Category Dropdown */}
      <div className="mb-4">
        <label className="mr-2">Category:</label>
        <Select
          options={categories.map((category) => ({ value: category.category_id, label: category.category_name }))}
          value={selectedCategory}
          onChange={(selectedOption) => setSelectedCategory(selectedOption)}
        />
      </div>

        {/* Home Input */}
        <div className="mb-4 flex">
  <div className="mr-4">
    <label className="mr-2">Home:</label>
    <input
      type="text"
      className="border p-2"
      value={homeTeam}
      onChange={(e) => setHomeTeam(e.target.value)}
      placeholder="Home"
    />
  </div>

  <div>
    <label className="mr-2">Away:</label>
    <input
      type="text"
      className="border p-2"
      value={awayTeam}
      onChange={(e) => setAwayTeam(e.target.value)}
      placeholder="Away"
    />
  </div>
</div>

      {/* Match Date Input */}
      <div className="mb-4">
        <label className="mr-2">Match Date:</label>
        <DatePicker
          selected={matchDate}
          onChange={(date) => setMatchDate(date)}
          dateFormat="yyyy-MM-dd"
        />
      </div>



<div className="mb-4">
<div className="mb-4">
<label className="mr-2">Match Time:</label>
      <DatePicker
        selected={matchTime}
        onChange={(time) => setMatchTime(time)}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={10}
        dateFormat="h:mm"
        timeCaption='Time'
        timeFormat="h:mm"
        showPopperArrow={true}
        // excludeTimes={[new Date(0, 0, 0, 12, 0), new Date(0, 0, 0, 23, 59)]}
        timeInputLabel="Time"
        customTimeInput={<input />}
        includeTimes={generateTimeOptions()}
      />
    </div>

    <div className="flex mt-2">
  <label className="mr-2">
    <input
      type="radio"
      value="morning" 
      checked={selectedTimeOfDay === "morning"}
      onChange={() => setSelectedTimeOfDay("morning")}
    />
    Morning
  </label>

  <label className="mr-2">
    <input
      type="radio"
      value="afternoon"  
      checked={selectedTimeOfDay === "afternoon"}
      onChange={() => setSelectedTimeOfDay("afternoon")}
    />
    Afternoon
  </label>

  <label className="mr-2">
    <input
      type="radio"
      value="night"
      checked={selectedTimeOfDay === "night"}
      onChange={() => setSelectedTimeOfDay("night")}
    />
    Night
  </label>
</div>
</div>

<label className="mr-2">
  <input
    type="checkbox"
    checked={isBetting}
    onChange={handleBettingChange}
  />
  Betting
</label>

{/* Correct Score Checkbox */}
<label className="mr-2">
  <input
    type="checkbox"
    checked={isCorrectScore}
    onChange={handleCorrectScoreChange}
  />
  Correct Score
</label>



      {/* Status Dropdown */}
      <div className="mb-4">
        <label className="mr-2">Status:</label>
        <Select
          options={statusOptions.map((status) => ({ value: status, label: status }))}
          value={selectedStatus}
          onChange={(selectedOption) => setSelectedStatus(selectedOption)}
        />
      </div>

      {/* Add Game Button */}
      <button
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        onClick={handleAddGame}
      >
        Add Game
      </button>
    </div>
  );
};

export default AddGames;
