import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const BidsDetail = () => {
  const { state } = useLocation();
  const [homeTeam, setHomeTeam] = useState('');
  const [awayTeam, setAwayTeam] = useState('');
  const [gameOn, setGameOn] = useState(null);

  useEffect(() => {
    try {
      // Parse the gameOn JSON string into an array of objects
      const gameOnObject = JSON.parse(state.gameOn);
  
      // Check if gameOnObject is an array and not empty
      if (Array.isArray(gameOnObject) && gameOnObject.length > 0) {
        // Log the gameId of the first item in the array
        console.log(gameOnObject[0].gameId);
  
        // Fetch home and away team names based on the game ID
        const fetchGameDetails = async () => {
          try {
            const response = await fetch(`/games/${gameOnObject[0].gameId}`);
            
            // Check if the response is successful
            if (!response.ok) {
              throw new Error(`Failed to fetch game details. Status: ${response.status}`);
            }
            
            const data = await response.json();
            
            // Check if data has home and away properties
            if (data.home && data.away) {
              setHomeTeam(data.home);
              setAwayTeam(data.away);
            } else {
              throw new Error("Invalid game details format");
            }
          } catch (error) {
            console.error('Error fetching game details:', error.message);
          }
        };
  
        fetchGameDetails();
      } else {
        throw new Error("Invalid gameOnObject format");
      }
    } catch (error) {
      console.error('Error parsing gameOnObject:', error.message);
    }
  }, [state.gameOn]);
  


  const renderButton = () => {
    if (state.status === 'pending') {
      return (
        <div className="text-center mt-6 mb-20">
          <button
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mr-2"
            onClick={handleClose}
          >
            Close
          </button>
        </div>
      );
    } else {
      return null;
    }
  };

  const handleClose = () => {
    // Implement logic for approving the bid
    console.log('Bid Closed:', state.bidID);
  };

  return (
    <div className="m-2 md:m-10 p-6 md:p-10 bg-white rounded-3xl shadow-lg" style={{ zIndex: 90 }}>
      <h1 className="text-3xl font-bold mb-6">Bid Details</h1>
      <div className="grid grid-cols-2 gap-4">
        <div>
          <p className="text-lg font-semibold">Bidder Name:</p>
          <p>{state.bidderName}</p>
        </div>
        <div>
          <p className="text-lg font-semibold">Bidder Phone:</p>
          <p>{state.bidderPhone}</p>
        </div>
        <div>
          <p className="text-lg font-semibold">Status:</p>
          <p>{state.status}</p>
        </div>
        <div>
          <p className="text-lg font-semibold">Amount:</p>
          <p>{state.amount}</p>
        </div>
        <div>
          <p className="text-lg font-semibold">Date:</p>
          <p>{state.date}</p>
        </div>
      </div>
      <div>
        <p className="text-lg font-semibold">Game:</p>
        <p>{state.gameOn}</p>
        <p>Home Team: {homeTeam}</p>
        <p>Away Team: {awayTeam}</p>
      </div>
      <div className="mt-8">{renderButton()}</div>
    </div>
  );
};

export default BidsDetail;
