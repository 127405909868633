import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Resize,
  Sort,
  ContextMenu,
  Filter,
  Page,
  ExcelExport,
  PdfExport,
  Inject,
  Toolbar,
  Edit
} from '@syncfusion/ej2-react-grids';
import {  gamesGrid } from '../data/dummy';

const Games = () => {
  const [game, setGame] = useState([]);
  const navigateTo = useNavigate();
  const toolbarOptions = ['ExcelExport'];
  const [bids, setbids] = useState([])

  const toolbarClick = (args) => {
    if (args.item.id === 'gridcomp_excelexport') {
      document.getElementById('gridcomp').ej2_instances[0].excelExport();
    } else if (args.item.id === 'gridcomp_pdfexport') {
      document.getElementById('gridcomp').ej2_instances[0].pdfExport();
    }
  };
  const handleAddNewGames = () => {
    console.log('Add New Category clicked');
    navigateTo('/addGames');
  };
  const handleDeleteCategory = (args) => {
    const selectedRowData = args.data;
    const target = args.target;
  
    if (selectedRowData && target && target.classList && target.classList.contains('e-delete')) {
      // Existing code for deleting a category
      axios
        .delete(`https://gowinadminback.433sportethiopia.com/deleteGame/${selectedRowData.game_id}`)
        .then((response) => {
          console.log('Game deleted:', response.data);
  
          axios
            .get('https://gowinadminback.433sportethiopia.com/currentgames')
            .then((response) => {
              const formattedData = response.data.map((row) => {
                const matchdate = new Date(row.matchdate);
                const timeZoneOffsetInMinutes = matchdate.getTimezoneOffset();
                const adjustedMatchDate = new Date(matchdate.getTime() - timeZoneOffsetInMinutes * 60 * 1000);
  
                const formattedMatchDate = adjustedMatchDate.toISOString().split('T')[0];
  
                return {
                  category: row.category_name,
                  home: row.home,
                  homeodd: row.homeodd,
                  away: row.away,
                  matchdate: formattedMatchDate,
                  matchtime: row.matchtime,
                  status: row.status,
                  sportType: row.sport_name,
                  country: row.country_name,
                  game_id: row.game_id,
                };
              });
  
              setGame(formattedData);
            })
            .catch((error) => {
              console.error('Error fetching updated data:', error);
            });
        })
        .catch((error) => {
          console.error('Error deleting category:', error);
        });
    } else {
      console.log('object');
  
      // Fetch user IDs with the selected row's gameId in their game_odd column
      if (selectedRowData) {
        const selectedGameId = selectedRowData.game_id;
  
        axios
          .get(`https://gowinadminback.433sportethiopia.com/fetchUserIdsAndBetsByGameId/${selectedGameId}`)
          .then((response) => {
            const userIds = response.data;
  
            // Additional parameters for navigation
            const params = {
              gameID: selectedRowData.game_id,
              home: selectedRowData.home,
              away: selectedRowData.away,
              category: selectedRowData.category,
              status: selectedRowData.status,
              country: selectedRowData.country,
              matchdate: selectedRowData.matchdate,
              matchtime: selectedRowData.matchtime,
              sportType: selectedRowData.sportType,
            };
  
            // Include user IDs and additional parameters in the navigation state
            const state = { ...params, userIds };
  
            navigateTo('/gamebids', { state });
          })
          .catch((error) => {
            console.error('Error fetching user IDs:', error);
          });
      }
    }
  };
  
  
  const handleActionComplete = (args) => {
    if (args.requestType === 'save') {
      const updatedData = args.data;

      // Add confirmation dialog for the user (optional)
      console.log(updatedData)
      const isConfirmed = window.confirm('Do you want to save changes?');

      if (isConfirmed) {
        axios
          .put(`https://gowinadminback.433sportethiopia.com/updateGame/${updatedData.game_id}`, updatedData)
          .then((response) => {
            console.log('Game updated:', response.data);
          })
          .catch((error) => {
            console.error('Error updating game:', error);
            alert('Error updating game. Please try again.'); // Notify the user about the error
          });
      }
    }
  };
  

  useEffect(() => {
    axios
      .get('https://gowinadminback.433sportethiopia.com/currentgames')
      .then((response) => {
        const formattedData = response.data.map((row) => {
          const matchdate = new Date(row.matchdate);
          const timeZoneOffsetInMinutes = matchdate.getTimezoneOffset();
          const adjustedMatchDate = new Date(matchdate.getTime() - timeZoneOffsetInMinutes * 60 * 1000);

          const formattedMatchDate = adjustedMatchDate.toISOString().split('T')[0];


          return {
            category: row.category_name,
            home: row.home,
            away: row.away,
            matchdate: formattedMatchDate,
            matchtime: row.matchtime,
            status: row.status,
            sportType: row.sport_name,
            country: row.country_name,
            game_id: row.game_id,
          };
        });

        setGame(formattedData);
        console.log(formattedData);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);





  return (
    <div className="m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl" style={{ zIndex: 90 }}>
              <div className="flex justify-end mb-4">
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          onClick={handleAddNewGames}
        >
            New Game
        </button>
      </div>
      <GridComponent
  id="gridcomp"
  actionComplete={(args) => handleActionComplete(args)}
  dataSource={game}
  allowPaging
  allowSorting
  allowResize
  allowFiltering
  editSettings={{ mode: 'Normal', showDeleteConfirmDialog: false }}
  pageSettings={{ pageSize: 10 }}
  allowExcelExport="true"
  toolbar={toolbarOptions}
  toolbarClick={toolbarClick}
  height="500"
  rowSelected={(args) => handleDeleteCategory(args)}

>
        <ColumnsDirective>
        
        <ColumnDirective
          headerText=""
          template={() => (
            <button className="e-tbar-btn e-btn" title="Delete" onClick={handleDeleteCategory}>
              <span className="e-btn-icon e-icons e-delete"></span>
            </button>
          )}
          width="80"
        />
          {gamesGrid.map((item, index) => (
            <ColumnDirective key={index} {...item} />
          ))}
    
        </ColumnsDirective>
        <Inject services={[Resize, Sort, ContextMenu, Filter, Page, Toolbar, ExcelExport, PdfExport,Edit]} />
      </GridComponent>
    </div>
  );
};

export default Games;
