import React from 'react';
import {  AiOutlineShoppingCart,AiOutlineHeart,AiTwotoneShopping} from 'react-icons/ai';
import { FaFolder } from 'react-icons/fa';
import { FiShoppingBag} from 'react-icons/fi';
import { IoMdContacts } from 'react-icons/io';
import { RiContactsLine } from 'react-icons/ri';
import { Query } from '@syncfusion/ej2-data';


export const currentBidsGrid = [

  {
    field: 'biddername',
    headerText: 'Name',
    width: '150',
    editType: 'dropdownedit',
    textAlign: 'Center',
  },
  { field: 'bidderphonenum',
    headerText: 'Phone Number',
    width: '150',
    textAlign: 'Center',
  },
  {
    field: 'status',
    headerText: 'Status',
    width: '150',
    editType: 'dropdownedit',
    textAlign: 'Center',
    edit: {
      params: {
        dataSource: [
          { value: 'closed', text: 'Closed' },
  
          { value: 'pending', text: 'Pending' },
        ],
        query: new Query().select(['value', 'text']),
        fields: { text: 'text', value: 'value' },
      },
    },
  }, 
  {
    field: 'amount',
    headerText: 'Amount',
    width: '150',
    textAlign: 'Center',
  },
  { 
    field: 'date',
    headerText: 'Date',
    width: '150',
    textAlign: 'Center' },
];

export const gamesGrid = [
    {
    field: 'category',
    headerText: 'Category',
    width: '150',
    textAlign: 'Center',
    isPrimaryKey:true

  }, 
   {
    field: 'home',
    headerText: 'Home',
    width: '150',
    textAlign: 'Center',
  },
 
  {
    field: 'away',
    headerText: 'Away',
    width: '150',
    textAlign: 'Center',
  },

     {
    field: 'matchdate',
    headerText: 'Match Date',
    width: '150',
    textAlign: 'Center',
  }, 
  {
    field: 'matchtime',
    headerText: 'Match Time',
    width: '150',
    textAlign: 'Center',
  }, 
  {
    field: 'status',
    headerText: 'Status',
    width: '150',
    editType: 'dropdownedit',
    textAlign: 'Center',
    edit: {
      params: {
        dataSource: [
          { value: 'open', text: 'Open' },
          { value: 'closed', text: 'Closed' },
          { value: 'pending', text: 'Pending' },
        ],
        query: new Query().select(['value', 'text']),
        fields: { text: 'text', value: 'value' },
      },
    },
  }, 
  {
    field: 'sportType',
    headerText: 'Sport Type',
    width: '150',
    textAlign: 'Center',
    isPrimaryKey:true
  },  
  {
    field: 'country',
    headerText: 'Country',
    width: '150',
    textAlign: 'Center',
    isPrimaryKey:true

  },

]


export const transactionGrid = [
  {
  field: 'type',
  headerText: 'Type',
  width: '100',
  textAlign: 'Center',
  isPrimaryKey:true

}, 
{
  field: 'status',
  headerText: 'Status',
  width: '100',
  editType: 'dropdownedit',
  textAlign: 'Center',
  edit: {
    params: {
      dataSource: [
        { value: 'approved', text: 'Approved' },

        { value: 'pending', text: 'Pending' },
      ],
      query: new Query().select(['value', 'text']),
      fields: { text: 'text', value: 'value' },
    },
  },
}, 
   {
  field: 'user',
  headerText: 'User',
  width: '150',
  textAlign: 'Center',
  isPrimaryKey:true

}, 
{
  field: 'phonenum',
  headerText: 'Phone Number',
  width: '150',
  textAlign: 'Center',
  isPrimaryKey:true

}, 
{
  field: 'bank',
  headerText: 'Selected Bank',
  width: '150',
  textAlign: 'Center',
  isPrimaryKey:true
},  
{
  field: 'accnum',
  headerText: 'Account Number',
  width: '150',
  textAlign: 'Center',
  isPrimaryKey:true
},  
{
  field: 'amount',
  headerText: 'Amount',
  width: '100',
  textAlign: 'Center',
  isPrimaryKey:true
},  

{
  field: 'date',
  headerText: 'Transaction Date',
  width: '150',
  textAlign: 'Center',
  isPrimaryKey:true

}, 



]
export const customertransactionGrid = [
  {
  field: 'type',
  headerText: 'Type',
  width: '100',
  textAlign: 'Center',
  isPrimaryKey:true

}, 
{
  field: 'status',
  headerText: 'Status',
  width: '100',
  editType: 'dropdownedit',
  textAlign: 'Center',
  edit: {
    params: {
      dataSource: [
        { value: 'approved', text: 'Approved' },

        { value: 'pending', text: 'Pending' },
      ],
      query: new Query().select(['value', 'text']),
      fields: { text: 'text', value: 'value' },
    },
  },
}, 

{
  field: 'phonenum',
  headerText: 'Phone Number',
  width: '150',
  textAlign: 'Center',
  isPrimaryKey:true

}, 
{
  field: 'bank',
  headerText: 'Selected Bank',
  width: '150',
  textAlign: 'Center',
  isPrimaryKey:true
},  
{
  field: 'accnum',
  headerText: 'Account Number',
  width: '150',
  textAlign: 'Center',
  isPrimaryKey:true
},  
{
  field: 'amount',
  headerText: 'Amount',
  width: '100',
  textAlign: 'Center',
  isPrimaryKey:true
},  

{
  field: 'date',
  headerText: 'Transaction Date',
  width: '150',
  textAlign: 'Center',
  isPrimaryKey:true

}, 



]

export const customerGrid = [
  {
  field: 'name',
  headerText: 'Name',
  width: '100',
  textAlign: 'Center',
  isPrimaryKey:true

}, 

   {
  field: 'phone',
  headerText: 'Phone Number',
  width: '150',
  textAlign: 'Center',
  isPrimaryKey:true

}, 
{
  field: 'accountnum',
  headerText: 'CBE Account Number',
  width: '150',
  textAlign: 'Center',
  isPrimaryKey:true
}, 
{
  field: 'balance',
  headerText: 'Balance',
  width: '150',
  textAlign: 'Center',
  isPrimaryKey:true
},  

]

export const feedbackGrid = [
  {
  field: 'name',
  headerText: 'Name',
  width: '70',
  textAlign: 'Center',
  isPrimaryKey:true

}, 

   {
  field: 'phone',
  headerText: 'Phone Number',
  width: '80',
  textAlign: 'Center',
  isPrimaryKey:true

}, 
{
  field: 'feedback',
  headerText: 'Feedback',
  width: '250',
  textAlign: 'Center',
  // wrapText:true,
  isPrimaryKey:true
}, 
{
  field: 'date',
  headerText: 'Date',
  width: '80',
  textAlign: 'Center',
  isPrimaryKey:true
},  

]
export const categoriesGrid = [

  {
    field: 'sportsType',
    headerText: 'Sport Type',
    width: '150',
    textAlign: 'Center',

  },
  {
    field: 'country',
    headerText: 'Country',
    width: '150',
    editType: 'dropdownedit',
    textAlign: 'Center',
  }, 
   {
    field: 'category',
    headerText: 'Category',
    width: '250',
    textAlign: 'Center',
  },


]

export const contextMenuItems = [
  'AutoFit',
  'AutoFitAll',
  'SortAscending',
  'SortDescending',
  'Copy',
  'Edit',
  'Delete',
  'Save',
  'Cancel',
  'PdfExport',
  'ExcelExport',
  'CsvExport',
  'FirstPage',
  'PrevPage',
  'LastPage',
  'NextPage',
];
