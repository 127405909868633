// Home.jsx
import React from 'react';
import Sidebar from '../components/Sidebar';
const Home = () => {
  return (
    <div className="flex">
      {/* <Sidebar /> */}
      <div className="flex-1 p-4">
        <div className="text-2xl font-bold mb-4">Home</div>
        <p>Main content goes here...</p>
      </div>
    </div>
  );
};

export default Home;
