import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Inject, Toolbar} from '@syncfusion/ej2-react-grids';
import { parse } from 'postcss';

const GameBids = () => {
  const { state } = useLocation();
  const [homeTeam, setHomeTeam] = useState('');
  const [awayTeam, setAwayTeam] = useState('');
  const [gameOn, setGameOn] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [bids, setBids] = useState([]);
  const [correctScores, setCorrectScores] = useState([])
  const [loading, setLoading] = useState(true);
  const [winner, setWinner] = useState('');
  const [homeTeamScore, setHomeTeamScore] = useState('');
  const [awayTeamScore, setAwayTeamScore] = useState('');
  const [correctscorewinner, setCorrectScoreWinner] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);


  const parseBetType = (betType) => {
    if (!homeTeam || !awayTeam) {
      return 'Loading...';
    }
  
    // Remove double quotes around the betType value
    betType = betType.replace(/"/g, '');
  
    switch (betType) {
      case 'home':
        return homeTeam;
      case 'away':
        return awayTeam;
      default:
        return 'draw';
    }
  };

  useEffect(() => {
    setHomeTeam(state.home || '');
    setAwayTeam(state.away || '');
    setGameOn(state);
    setSelectedStatus(state.status || '');

  }, [state]);

  useEffect(() => {

    if (state.gameID && selectedStatus === 'closed') {
      axios.get(`https://gowinadminback.433sportethiopia.com/fetchUserIdsAndBetsByGameId/${state.gameID}`)
        .then(response => {
          const formattedData = response.data.map((row) => ({
            userid:row.user_id,
            player_name: row.name,
            phone_num: row.phone_num,
            betType: parseBetType(row.betType),
            amount: row.amount,
            possibleWin: ((row.amount * 2) - (row.amount * 0.1)).toFixed(2),
          }));

          setBids(formattedData);
          setLoading(false);
        })
        .catch(error => {
          console.error('Error fetching bids:', error);
          setLoading(false);
        });
    }
    else if (state.gameID && (selectedStatus === 'open' || selectedStatus==='pending' ||selectedStatus==='paid')) {
      axios.get(`https://gowinadminback.433sportethiopia.com/fetchUserIdsAndBetsByGameId/${state.gameID}`)
        .then(response => {
          const formattedData = response.data.map((row) => ({
            userid:row.user_id,
            player_name: row.name,
            phone_num: row.phone_num,
            betType: parseBetType(row.betType),
            amount: row.amount,
            possibleWin: ((row.amount * 2) - (row.amount * 0.1)).toFixed(2),
          }));

          setBids(formattedData);
          setLoading(false);
        })
        .catch(error => {
          console.error('Error fetching bids:', error);
          setLoading(false);
        });
    }
  }, [state, homeTeam, awayTeam, winner, selectedStatus]);
  useEffect(() => {
    if (state.gameID) {
      axios.get(`https://gowinadminback.433sportethiopia.com/fetchCorrectScoreByGameId/${state.gameID}`)
        .then(response => {
          console.log(response)
          const formattedData = response.data.map((row) => ({
            userid:row.user_id,
            player_name: row.name,
            phone_num: row.phone_num,
            betType: row.betType,
            amount: row.amount,
            possibleWin: ((row.amount * 3) - (row.amount * 0.2)).toFixed(2),
          }));

          setCorrectScores(formattedData);
          setLoading(false);
        })
        .catch(error => {
          console.error('Error fetching bids:', error);
          setLoading(false);
        });
    }
  }, [state,homeTeamScore,awayTeamScore,selectedStatus]);

  useEffect(() => {
    setWinner('');
  }, [state.status]);

  const onPayButtonClick = async () => {
    console.log("Pay Winners button clicked");
    const isConfirmed = window.confirm("Are you sure you want to pay the winners? This action is irreversible.");

    if (!isConfirmed) {
      // User canceled the operation, you can handle this as needed
      console.log("User canceled the payment operation.");
      return;
    }
    const correctScoresData= correctScores.filter(score => {
      const [scoreHome, scoreAway] = score.betType.split(':').map(s => s.trim());
      return (
        parseInt(scoreHome) === parseInt(homeTeamScore) &&
        parseInt(scoreAway) === parseInt(awayTeamScore)
      );
    })
    // Log all row data from the first grid table
    const firstGridData = bids.filter(bid => bid.betType === winner);
    console.log(firstGridData)
    console.log("First Grid Data:", firstGridData);
    const combinedData = [...firstGridData, ...correctScoresData];

    // Create an object to store total possible win for each user
    const userTotalPossibleWin = {};
  
    // Iterate through each row in the firstGridData
    for (const row of combinedData) {
      try {
        // Fetch user balance from the customer table using the user_id
        const userBalanceResponse = await axios.get(`https://gowinadminback.433sportethiopia.com/fetchUserBalance/${row.userid}`);
        const userBalance = userBalanceResponse.data.balance;
  
        // Add the possibleWin to the user's total in the object
        const totalPossibleWin = (userTotalPossibleWin[row.userid] || 0) + parseFloat(row.possibleWin);
        userTotalPossibleWin[row.userid] = totalPossibleWin;
        console.log(`Adding ${row.possibleWin} to total possible win for user ${row.userid}. Total: ${totalPossibleWin}`);
      } catch (error) {
        console.error('Error fetching user balance:', error);
      }
    }
  
    // After calculating total possible win for each user, update their balances
    for (const userid in userTotalPossibleWin) {
      try {
        // Fetch user balance from the customer table using the user_id
        const userBalanceResponse = await axios.get(`https://gowinadminback.433sportethiopia.com/fetchUserBalance/${userid}`);
        const userBalance = userBalanceResponse.data.balance;
  
        // Update the user's balance in the customer table
        const updatedBalance = userBalance + userTotalPossibleWin[userid];
        console.log(updatedBalance);
        await axios.put(`https://gowinadminback.433sportethiopia.com/updateUserBalance/${userid}`, {
          balance: updatedBalance,
        });
  
        console.log(`Updated balance for user ${userid}: ${updatedBalance}`);
      } catch (error) {
        console.error('Error updating user balance:', error);
      }
    }
    try {
      await axios.put(`https://gowinadminback.433sportethiopia.com/updateGameStatus/${state.gameID}`, {
        status: 'paid',
      });
  
      // Update local state to reflect the change
      setGameOn((prevState) => ({
        ...prevState,
        status: 'paid',
      }));
  
      setSelectedStatus('paid'); // If needed, update the selected status in your component state
    } catch (error) {
      console.error('Error updating game status:', error);
    }
  };
  


  const gridColumns = [
    { field: 'player_name', headerText: 'Player Name', width: '25%', textAlign: 'Center' },
    { field: 'phone_num', headerText: 'Phone Number', width: '20%', textAlign: 'Center' },
    { field: 'betType', headerText: 'Bet On', width: '30%', textAlign: 'Center' },
    { field: 'amount', headerText: 'Amount', width: '15%', textAlign: 'Center' },
    { field: 'possibleWin', headerText: 'Possible Win', width: '30%', textAlign: 'Center' },

  ];

  const correctgridColumns = [
    { field: 'player_name', headerText: 'Player Name', width: '25%', textAlign: 'Center' },
    { field: 'phone_num', headerText: 'Phone Number', width: '20%', textAlign: 'Center' },
    { field: 'betType', headerText: 'Bet ', width: '30%', textAlign: 'Center' },
    { field: 'amount', headerText: 'Amount', width: '15%', textAlign: 'Center' },
    { field: 'possibleWin', headerText: 'Possible Win', width: '30%', textAlign: 'Center' },

  ];
  const handleWinner = (e) => {
    const selectedWinner = e.target.value;
    console.log(`Selected Winner: ${selectedWinner}`);
    setWinner(selectedWinner);
  };
  const handleHomeTeamScoreChange = (e) => {
    const score = e.target.value;
    if (/^\d+$/.test(score)) {
      console.log(`Home Team Score: ${score}`);
      setHomeTeamScore(score);

      // Update correct score winner when home team score changes
      updateCorrectScoreWinner(score, awayTeamScore);
    } else {
      console.error('Please enter a valid positive number for Home Team Score');
    }
  };

  const handleAwayTeamScoreChange = (e) => {
    const score = e.target.value;
    if (/^\d+$/.test(score)) {
      console.log(`Away Team Score: ${score}`);
      setAwayTeamScore(score);

      // Update correct score winner when away team score changes
      updateCorrectScoreWinner(homeTeamScore, score);
    } else {
      console.error('Please enter a valid positive number for Away Team Score');
    }
  };
  const updateCorrectScoreWinner = (homeScore, awayScore) => {
    const calculatedWinner = `${homeScore}:${awayScore}`;
    setCorrectScoreWinner(calculatedWinner);
  };
  

  
  const renderWinnerSelection = () => {
    if (selectedStatus === 'closed') {
      return (
        <div className="mb-4">
          <label className="mr-2">Select Winner:</label>
          <select value={winner} onChange={handleWinner}>
            <option value="" disabled>Select Winner</option>
            <option value={homeTeam}>{homeTeam}</option>
            <option value={awayTeam}>{awayTeam}</option>
            <option value="draw">Draw</option>
          </select>

          {/* Add number fields for homeTeam and awayTeam scores */}
          <label className="ml-4 mr-2">{homeTeam} Score:</label>
          <input
            type="number"
            value={homeTeamScore}
            onChange={handleHomeTeamScoreChange}
            placeholder="Enter Home Team Score"
          />

          <label className="ml-4 mr-2">{awayTeam} Score:</label>
          <input
            type="number"
            value={awayTeamScore}
            onChange={handleAwayTeamScoreChange}
            placeholder="Enter Away Team Score"
          />
        </div>
      );
    }
    else if(selectedStatus==='open'||selectedStatus==='pending'){
            return (
        <div className="mb-4">
          <label className="mr-2">Select Status:</label>
          <select value={selectedStatus} onChange={handleStatusChange}>
            <option value="" disabled>Select Status</option>
            <option value="open">Open</option>
            <option value="pending">Pending</option>
            <option value="closed">Closed</option>
          </select>
        </div>
      );
    }
    else {
return null;
    }
  };

  useEffect(() => {
    setWinner('');
  }, [selectedStatus]);

  const handleStatusChange = async (e) => {
    const newStatus = e.target.value;

    // Check if the selected value is not an empty string before making the API call
    if (newStatus !== '') {
      try {
        await axios.put(`https://gowinadminback.433sportethiopia.com/updateGameStatus/${gameOn.gameID}`, {
          status: newStatus,
        });

        setGameOn((prevState) => ({
          ...prevState,
          status: newStatus,
        }));

        setSelectedStatus(newStatus);
      } catch (error) {
        console.error('Error updating game status:', error);
      }
    }
  };
  const handleDeleteBid = (args) => {
    const selectedRowData = args.data;
    const target = args.target;
      if (selectedRowData && target.classList.contains('e-delete')) {
console.log(selectedRowData.userid)
axios
.delete(`https://gowinadminback.433sportethiopia.com/deleteCBid/${selectedRowData.userid}`)
.then((response) => {
  console.log('Bid deleted:', response.data);
  axios.get(`https://gowinadminback.433sportethiopia.com/fetchUserIdsAndBetsByGameId/${state.gameID}`)
  .then(response => {
    const formattedData = response.data.map((row) => ({
      userid:row.user_id,
      player_name: row.name,
      phone_num: row.phone_num,
      betType: parseBetType(row.betType),
      amount: row.amount,
      possibleWin: ((row.amount * 2) - (row.amount * 0.1)).toFixed(2),
    }));

    setBids(formattedData);
    setLoading(false);
  })
  .catch(error => {
    console.error('Error fetching bids:', error);
    setLoading(false);
  });

  
})
.catch((error) => {
  console.error('Error deleting category:', error);
});
    }
  };
  const handleDeleteCorrectBid = (args) => {
    const selectedRowData = args.data;
    const target = args.target;
      if (selectedRowData && target.classList.contains('e-delete')) {
console.log(selectedRowData.userid)
axios
.delete(`https://gowinadminback.433sportethiopia.com/deleteCorrectBid/${selectedRowData.userid}`)
.then((response) => {
  console.log('Bid deleted:', response.data);

  axios.get(`https://gowinadminback.433sportethiopia.com/fetchCorrectScoreByGameId/${state.gameID}`)
  .then(response => {
    console.log(response)
    const formattedData = response.data.map((row) => ({
      userid:row.user_id,
      player_name: row.name,
      phone_num: row.phone_num,
      betType: row.betType,
      amount: row.amount,
      possibleWin: ((row.amount * 3) - (row.amount * 0.2)).toFixed(2),
    }));

    setCorrectScores(formattedData);
    setLoading(false);
  })
    .catch((error) => {
      console.error('Error fetching updated data:', error);
    });
})
.catch((error) => {
  console.error('Error deleting category:', error);
});
    }
  };

  return (
    <div className="m-2 md:m-10 p-6 md:p-10 bg-white rounded-3xl shadow-lg" style={{ zIndex: 90 }}>
      {renderWinnerSelection()}
 
      <h1>{homeTeam} vs {awayTeam}</h1>
      {correctscorewinner && <p>Correct Score Winner: {correctscorewinner}</p>}

      {selectedStatus === 'closed' && winner && (
        <button className="bg-blue-500 text-white px-4 py-2 rounded-md mt-4" onClick={onPayButtonClick}>
          Pay Winners
        </button>
      )}
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div>
          {selectedStatus === 'closed' ? (
            <GridComponent dataSource={bids.filter(bid => bid.betType === winner)} allowSorting allowPaging pageSettings={{ pageSize: 20 }}  rowSelected={(args) => handleDeleteBid(args)}>
      
              <ColumnsDirective>
              <ColumnDirective
          headerText=""
          template={() => (
            <button className="e-tbar-btn e-btn" title="Delete" onClick={handleDeleteBid}>
              <span className="e-btn-icon e-icons e-delete"></span>
            </button>
          )}
          width="80"
        />
                {gridColumns.map((column, index) => (
                  <ColumnDirective key={index} {...column} />
                ))}
              </ColumnsDirective>
              <Inject services={[Page]} />
            </GridComponent>
          ) : (
            <GridComponent dataSource={bids} allowSorting allowPaging pageSettings={{ pageSize: 20 }}>
              <ColumnsDirective>
                {gridColumns.map((column, index) => (
                  <ColumnDirective key={index} {...column} />
                ))}
              </ColumnsDirective>
              <Inject services={[Page]} />
            </GridComponent>
          )}
<br/><br/><br/>
<h3>Correct Score Bids</h3>
<h5>{homeTeam}  {homeTeamScore} : {awayTeamScore}  {awayTeam}</h5>
{selectedStatus === 'closed' ? (


     <GridComponent dataSource={correctScores.filter(score => {
      const [scoreHome, scoreAway] = score.betType.split(':').map(s => s.trim());
      return (
        parseInt(scoreHome) === parseInt(homeTeamScore) &&
        parseInt(scoreAway) === parseInt(awayTeamScore)
      );
    })} allowSorting allowPaging pageSettings={{ pageSize: 20 }}         rowSelected={(args) => handleDeleteCorrectBid(args)}>
      <ColumnsDirective>
      <ColumnDirective
          headerText=""
          template={() => (
            <button className="e-tbar-btn e-btn" title="Delete" onClick={handleDeleteCorrectBid}>
              <span className="e-btn-icon e-icons e-delete"></span>
            </button>
          )}
          width="80"
        />
        {correctgridColumns.map((column, index) => (
          <ColumnDirective key={index} {...column} />
        ))}
      </ColumnsDirective>
      <Inject services={[Page]} />
    </GridComponent>

):(
  <GridComponent dataSource={correctScores} allowSorting allowPaging pageSettings={{ pageSize: 20 }}>
<ColumnsDirective>
  {correctgridColumns.map((column, index) => (
    <ColumnDirective key={index} {...column} />
  ))}
</ColumnsDirective>
<Inject services={[Page]} />
</GridComponent>
)
}

</div>
      )}
    </div>
  );
};

export default GameBids;
