

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Resize,
  Sort,
  ContextMenu,
  Filter,
  Page,
  ExcelExport,
  PdfExport,
  Inject,
  Toolbar,
  Edit,
  Search
} from '@syncfusion/ej2-react-grids';
import {  customerGrid } from '../data/dummy';

const Customers = () => {
  const [customer, setCustomer] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [userIds, setUserIds] = useState([]);

  const navigateTo = useNavigate();
  const toolbarOptions = ['ExcelExport'];
const BOT_TOKEN='6717614392:AAF1lKDz_uHkQskJ3ezhsbtMiRP2inE-tfI'
  const toolbarClick = (args) => {
    if (args.item.id === 'gridcomp_excelexport') {
      document.getElementById('gridcomp').ej2_instances[0].excelExport();
    } else if (args.item.id === 'gridcomp_pdfexport') {
      document.getElementById('gridcomp').ej2_instances[0].pdfExport();
    }
  };
  const onSearch = (args) => {
    setSearchText(args.target.value);
    // Apply search on the grid
    document.getElementById('gridcomp').ej2_instances[0].search(args.target.value);
  };


  const sendMessageToCustomer = (args) => {
    const selectedRowData = args.data;

    const params = {
        userid:selectedRowData.id,
      name:selectedRowData.name,
      phone:selectedRowData.phone,
      accountnum:selectedRowData.accountnum,
      balance:selectedRowData.balance,

    };

    navigateTo('/sendMessage', { state: params });
  };
  const broadcastMessage = () => {
    // Use userIds directly instead of making another request
    console.log('Broadcasting a message to all customers with user IDs:', userIds);
    navigateTo('/broadcast', { state: { userIds } });
  };
  useEffect(() => {
    axios
      .get('https://gowinadminback.433sportethiopia.com/customers')
      .then((response) => {
        const formattedData = response.data.map((row) => {

          return {
            id:row.user_id,
            name: row.name,
            phone: row.phone_num,
            accountnum: row.cbeaccnumber,
            balance:row.balance,
 
          };
        });

        setCustomer(formattedData);
        const extractedUserIds = response.data.map((row) => row.user_id);
        setUserIds(extractedUserIds);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);





  return (
    <div>
    <div className="mb-4 flex">
    <input
      type="text"
      value={searchText}
      onChange={onSearch}
      placeholder="Search ... "
      className="p-2 mr-2 mb-2 "
    />
    <button
      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ml-20 "
      onClick={broadcastMessage}
    >
      Broadcast a Message
    </button>
  </div>
      <GridComponent
  id="gridcomp"
  dataSource={customer}
  allowPaging
  allowSorting
  allowResize
  allowFiltering
  editSettings={{  mode: 'Normal', showDeleteConfirmDialog: false }}
  pageSettings={{ pageSize: 40 }}
  allowExcelExport="true"
  toolbar={toolbarOptions}
  toolbarClick={toolbarClick}
  height="500"
  rowSelected={sendMessageToCustomer}

>
        <ColumnsDirective>
        
     
          {customerGrid.map((item, index) => (
            <ColumnDirective key={index} {...item} />
          ))}

        </ColumnsDirective>
        <Inject services={[Resize, Sort, ContextMenu, Filter, Page, Toolbar, ExcelExport, PdfExport,Edit,Search]} />
      </GridComponent>
      </div>
  );
};

export default Customers;
