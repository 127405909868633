import React, {useState,useEffect} from 'react';
import { useLocation ,useNavigate} from 'react-router-dom';
import axios from 'axios';
// TransactionDetails component

const TransactionDetails = () => {
  const { state } = useLocation();
  const location = useLocation();
  const selectedRowData = location.state || {};
  const [transaction, setPayBill] = useState('');
  const botToken = '6717614392:AAF1lKDz_uHkQskJ3ezhsbtMiRP2inE-tfI';
  const navigateTo = useNavigate();
  const [messageText, setMessageText] = useState('');
  const [approvedAmount, setApprovedAmount] = useState('');


  const handleApprove = () => {
    // Assuming selectedRowData.tid contains the transaction_id
    const transactionId = selectedRowData.tid;
    const amountToApprove = approvedAmount || selectedRowData.amount;

    axios.put(`https://gowinadminback.433sportethiopia.com/approveTransaction/${transactionId}`, {
      status: 'approved',
      amount:amountToApprove,
      userId:selectedRowData.userid
    })
      .then(response => {
        console.log('Transaction approved successfully', response.data);
        const messageText = `Your ${amountToApprove} ETB deposit has been approved and your account has been credited. Thank you!`;
        axios.post(`https://api.telegram.org/bot${botToken}/sendMessage`, {
          chat_id: selectedRowData.userid,
          text: messageText,
        })
          .then(response => {
            console.log('Private message sent successfully', response.data);
          })
          .catch(error => {
            console.error('Error sending private message:', error);
            // Handle the error, display a message, or take appropriate action
          });
          
          navigateTo('/transaction')
        // You may want to update the UI or perform other actions here
      })
      .catch(error => {
        console.error('Error approving transaction:', error);
        // Handle the error, display a message, or take appropriate action
      });
  };
  const handleWithdrawalApprove =()=>{
    const transactionId = selectedRowData.tid;
  
    // Send a request to the backend to update the status to "Approved" using Axios
    axios.put(`https://gowinadminback.433sportethiopia.com/approveWithdrawalTransaction/${transactionId}`, {
      status: 'approved',
      amount:selectedRowData.amount,
      userId:selectedRowData.userid
    })
      .then(response => {
        console.log('Transaction approved successfully', response.data);
        const messageText = `Your ${selectedRowData.amount} ETB withdraw has been approved and your account has been debited. Thank you!`;
        axios.post(`https://api.telegram.org/bot${botToken}/sendMessage`, {
          chat_id: selectedRowData.userid,
          text: messageText,
        })
          .then(response => {
            console.log('Private message sent successfully', response.data);
          })
          .catch(error => {
            console.error('Error sending private message:', error);
            // Handle the error, display a message, or take appropriate action
          });
        navigateTo('/transaction')
        // You may want to update the UI or perform other actions here
      })
      .catch(error => {
        console.error('Error approving transaction:', error);
        // Handle the error, display a message, or take appropriate action
      });
  }
  const handlePrivateMessage = () => {
    const userId=selectedRowData.userid
    axios
      .post(`https://api.telegram.org/bot${botToken}/sendMessage`, {
        chat_id: userId,
        text: messageText,
      })
      .then((response) => {
        console.log('Private message sent successfully', response.data);
      })
      .catch((error) => {
        console.error('Error sending private message:', error);
      });
  };


  const renderButton = () => {
    if (selectedRowData.status === 'pending' && selectedRowData.type === 'deposit') {
      return (
        <div className="text-center mt-6 mb-20">
                    <div>
            <label className="block text-sm font-medium text-gray-700">Enter Amount to Approve:</label>
            <input
              type="text"
              className="mt-1 p-2 border border-gray-300 rounded"
              placeholder="Enter amount"
              value={approvedAmount}
              onChange={e => setApprovedAmount(e.target.value)}
            />
          </div>
          <button className="bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded" onClick={handleApprove}>
            Approve
          </button>
    
          <div className="mt-6">
            <textarea
              className="w-full p-2 border border-gray-300 rounded"
              placeholder="Send a private message here..."
              rows="3"
              value={messageText}
              onChange={(e) => setMessageText(e.target.value)}
  
            ></textarea>
          </div>
    
          <button
            className="mt-4 bg-green-500 hover:bg-green-700 text-white font-semibold py-2 px-4 rounded"
            onClick={handlePrivateMessage}>
            Send Private Message
          </button>
        </div>
      );
    }
    
    if (selectedRowData.status==='pending' && selectedRowData.type==='withdraw') {

      return (
        <div className="text-center mt-6 mb-20"> 
          <button className="bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded" onClick={handleWithdrawalApprove}>
            Approve
          </button>

        </div>
      );
    }

    else {
      return null; // Return null if not approved
    }
  };


  useEffect(() => {
    fetch(`https://api.telegram.org/bot${botToken}/getFile?file_id=${state.image}`)
      .then(response => response.json())
      .then(data => {
        const filePath = data.result.file_path;
        const imageUrl = `https://api.telegram.org/file/bot${botToken}/${filePath}`;
        setPayBill(imageUrl);
      })
      .catch(error => {
        console.error('Error fetching image data:', error);
      });
  }, [state.image]);

  return (
    <div className="m-2 md:m-10 p-6 md:p-10 bg-white rounded-3xl shadow-lg" style={{ zIndex: 90 }}>
      <h1 className="text-3xl font-bold mb-6">Transaction Details</h1>
      <div className="grid grid-cols-2 gap-4">
        <img src={transaction} alt="Transaction" />
      </div>
      <div className="mt-8">
        {renderButton()}
      </div>
    </div>
  );
};


export default TransactionDetails;
