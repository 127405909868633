import React, { useState } from 'react';
import { useLocation,useNavigate } from 'react-router-dom';
import axios from 'axios';

const BroadcastMessage = () => {
    const location = useLocation();
    const botToken = '6717614392:AAF1lKDz_uHkQskJ3ezhsbtMiRP2inE-tfI';


  const [message, setMessage] = useState('');
  const userIds = location.state?.userIds || [];

  const handleBroadcast = () => {
    // Implement additional logic if needed
    console.log('Broadcasting message:', message);
    console.log('User IDs:', userIds);

    // Iterate over each user ID and send a private message
    userIds.forEach((userId) => {
      axios
        .post(`https://api.telegram.org/bot${botToken}/sendMessage`, {
          chat_id: userId,
          text: message,
        })
        .then((response) => {
          console.log('Private message sent successfully to user ID:', userId, response.data);
        })
        .catch((error) => {
          console.error('Error sending private message to user ID:', userId, error);
        });
    });
  };

  return (
    <div className="flex items-center justify-center">
      <div className="bg-gray-500 opacity-75"></div>
      <div className="bg-white p-8 rounded-lg z-10 w-96">
        <h2 className="text-2xl font-bold mb-4">Broadcast Message</h2>
        <textarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Enter your message..."
          className="w-full p-2 border border-bold mb-4 rounded font-bold"
          rows={6}
        ></textarea>
        <div className="flex justify-end">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
            onClick={handleBroadcast}
          >
            Broadcast
          </button>
        </div>
      </div>
    </div>
  );
};

export default BroadcastMessage;
