
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

const FeedResponse = () => {
  const location = useLocation();
  const params = location.state;
  const navigateTo = useNavigate();

  const [message, setMessage] = useState('');
  
  const BOT_TOKEN='6717614392:AAF1lKDz_uHkQskJ3ezhsbtMiRP2inE-tfI'


const handleSendMessage = () => {
    const confirmation = window.confirm('Sending a new response will remove any previously existing responses.Are you sure you want to send this message and update the response?');

    if (confirmation) {
        const telegramMessage = `You have received a response for your feedback\n\nFeedback: ${params.feedback}\n\nResponse: ${message} \n\n Please feel free to reach out again🙏. Thanks!`;

    console.log(`Sending message to ${params.userid} (${params.phone}): ${message}`);
    
    axios.post(`https://api.telegram.org/bot${BOT_TOKEN}/sendMessage`, {
      chat_id: params.userid,
      text: telegramMessage,
    })
    .then(response => {
      console.log('Private message sent successfully', response.data);
      // Update response in the feedback table
      axios.put(`https://gowinadminback.433sportethiopia.com/feedback/${params.fid}`, {
        response: message,
      })
      .then(response => {
        console.log('Response updated successfully', response.data);
      })
      .catch(error => {
        console.error('Error updating response:', error);
      });
    })
    .catch(error => {
      console.error('Error sending private message:', error);
    });
    
    navigateTo('/feedback');
    setMessage('');
}

  };
  

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Send Message to {params.name}</h1>

      <div className="mb-4">
        <label className="mr-2 font-bold">Customer Name:</label>
        <span>{params.name}</span>
      </div>

      <div className="mb-4">
        <label className="mr-2 font-bold">Customer Phone:</label>
        <span>{params.phone}</span>
      </div>

      <div className="mb-4">
        <label className="mr-2 font-bold">Feedback:</label>
        <span>{params.feedback}</span>
      </div>

      <div className="mb-4">
        <label className="mr-2 font-bold">Response:</label>
        {params.response ? (
          <span>{params.response}</span>
        ) : (
          <span>No response</span>
        )}
      </div>

      <div className="mb-4">
        <label className="mr-2 font-bold">Message:</label>
        <textarea
          className="border p-2 w-full h-32"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Type your message here..."
        />
      </div>

      <button
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        onClick={handleSendMessage}
      >
        Send Response
      </button>
    </div>
  );
};

export default FeedResponse;
