import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const AddCategories = () => {
  const [sportType, setSportType] = useState('');
  const [country, setCountry] = useState('');
  const [category, setCategory] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [sports, setSports] = useState([]);
  const [countries, setCountries] = useState([]);
  const [newCountry, setNewCountry] = useState('');
  const [newSport, setNewSport] = useState('');

  const navigateTo = useNavigate();

  useEffect(() => {
    // Fetch sports and countries when the component mounts
    fetchSportsAndCountries();
  }, []);

  const fetchSportsAndCountries = () => {
    axios.get('https://gowinadminback.433sportethiopia.com/sports')
      .then(response => setSports(response.data))
      .catch(error => console.error('Error fetching sports:', error));

    axios.get('https://gowinadminback.433sportethiopia.com/countries')
      .then(response => setCountries(response.data))
      .catch(error => console.error('Error fetching countries:', error));
  };

  const handleAdd = () => {
    const data = {
      sportType,
      country,
      category,
    };

    // Reset previous messages
    setError('');
    setSuccessMessage('');

    axios.post('https://gowinadminback.433sportethiopia.com/addCategory', data)
      .then(response => {
        navigateTo('/gameCategories');
        setSportType('');
        setCountry('');
        setCategory('');
      })
      .catch(error => {
        setError(error.response?.data?.message || 'An error occurred.');
        console.error('Error:', error.response?.data?.message || 'An error occurred.');
      });
  };

  const handleAddSport = () => {
    if (!newSport) {
      setError('Sport name is required.');
      return;
    }
  
    const existingSport = sports.find(
      (sport) => sport.sport_name.toLowerCase() === newSport.toLowerCase()
    );
  
    if (existingSport) {
      setError('Sport with the same name already exists.');
      return;
    }
  
    axios.post('https://gowinadminback.433sportethiopia.com/addSport', { sportName: newSport })
      .then(response => {
        setSuccessMessage(response.data.message);
        setNewSport('');
        fetchSportsAndCountries(); // Update sports dropdown
      })
      .catch(error => {
        setError(error.response?.data?.message || 'An error occurred.');
        console.error('Error:', error.response?.data?.message || 'An error occurred.');
      });
  };
  
  const handleAddCountry = () => {
    if (!newCountry) {
      setError('Country name is required.');
      return;
    }
  
    const existingCountry = countries.find(
      (country) => country.country_name.toLowerCase() === newCountry.toLowerCase()
    );
  
    if (existingCountry) {
      setError('Country with the same name already exists.');
      return;
    }
  
    axios.post('https://gowinadminback.433sportethiopia.com/addCountry', { countryName: newCountry })
      .then(response => {
        setSuccessMessage(response.data.message);
        setNewCountry('');
        fetchSportsAndCountries(); // Update countries dropdown
      })
      .catch(error => {
        setError(error.response?.data?.message || 'An error occurred.');
        console.error('Error:', error.response?.data?.message || 'An error occurred.');
      });
  };
  

  return (
    <div className="justify-center min-h-screen">
      <div className="flex flex-col items-center p-4 border border-gray-300 rounded-lg shadow-lg">
        <h1 className="text-2xl font-bold mb-4">Add Category</h1>
        {error && (
          <div className="text-red-500 mb-4">{error}</div>
        )}

        {successMessage && (
          <div className="text-green-500 mb-4">{successMessage}</div>
        )}
        <div className="flex items-center mb-4">
          <label className="mr-2">Sport:</label>
          <select
            className="border p-2 focus:outline-none focus:border-blue-500"
            value={sportType}
            onChange={(e) => setSportType(e.target.value)}
          >
            <option value="">Select Sport</option>
            {sports.map((sport) => (
              <option key={sport.sport_id} value={sport.sport_name}>{sport.sport_name}</option>
            ))}
          </select>
        </div>

        <div className="flex items-center mb-4">
          <label className="mr-2">Country:</label>
          <select
            className="border p-2 focus:outline-none focus:border-blue-500"
            value={country}
            onChange={(e) => setCountry(e.target.value)}
          >
            <option value="">Select Country</option>
            {countries.map((country) => (
              <option key={country.country_id} value={country.country_name}>{country.country_name}</option>
            ))}
          </select>
        </div>

        <div className="flex items-center mb-4">
          <label className="mr-2">Category:</label>
          <input
            type="text"
            className="border p-2 focus:outline-none focus:border-blue-500"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            placeholder="Enter Category"
          />
        </div>

 =
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          onClick={handleAdd}
        >
          Add
        </button>
      </div>

      <div className="flex flex-col items-center p-4 border border-gray-300 rounded-lg shadow-lg mt-4">
        <h2 className="text-2xl font-bold mb-4">Add Sport</h2>
        <div className="flex items-center mb-4">
          <label className="mr-2">New Sport:</label>
          <div className="flex items-center">
            <input
              type="text"
              className="border p-2 focus:outline-none focus:border-blue-500"
              value={newSport}
              onChange={(e) => setNewSport(e.target.value)}
              placeholder="Enter Sport Type"
            />
            <button
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 ml-2 rounded"
              onClick={handleAddSport}
            >
              Add
            </button>
          </div>
        </div>


      </div>

      <div className="flex flex-col items-center p-4 border border-gray-300 rounded-lg shadow-lg mt-4">
        <h2 className="text-2xl font-bold mb-4">Add Country</h2>
        <div className="flex items-center mb-4">
          <label className="mr-2">New Country:</label>
          <div className="flex items-center">
            <input
              type="text"
              className="border p-2 focus:outline-none focus:border-blue-500"
              value={newCountry}
              onChange={(e) => setNewCountry(e.target.value)}
              placeholder="Enter Country"
            />
            <button
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 ml-2 rounded"
              onClick={handleAddCountry}
            >
              Add
            </button>
          </div>
        </div>


      </div>
    </div>
  );
};

export default AddCategories;
