import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { FiSettings } from 'react-icons/fi';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import './App.css'
import AuthContext from './AuthContext';
import Login from './pages/Login';
import Home from './pages/Home';
import Sidebar from './components/Sidebar'
import Header from './components/Header';
import CurrentBids from './pages/CurrentBids'
import BidsDetail from './pages/BidsDetail';
import GameCategories from './pages/GameCategories';
import AddGames from './pages/AddGames';
import AddCategories from './pages/AddCategories';
import Games from './pages/Games';
import Transaction from './pages/Transaction';
import TransactionDetails from './pages/TransactionDetails';
import GameBids from './pages/GameBids';
import Customers from './pages/Customers';
import SendMessage from './pages/SendMessage';
import BroadcastMessage from './pages/BroadcastMessage';
import Feedback from './pages/Feedback';

import Hello from './pages/Hello';
import NotFound from './pages/NotFound';
import FeedResponse from './pages/FeedResponse';




const App = () => {
  // Retrieve authentication status from local storage, defaulting to true if not present
  const storedAuthentication = localStorage.getItem('authenticated');
  const initialAuthentication = storedAuthentication ? JSON.parse(storedAuthentication) : false;
  const [authenticated, setAuthenticated] = useState(initialAuthentication);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);

  // useEffect to subscribe to changes in local storage
  useEffect(() => {
    const handleStorageChange = () => {
      // When local storage changes, update the authenticated state
      const updatedAuthentication = localStorage.getItem('authenticated');
      setAuthenticated(updatedAuthentication ? JSON.parse(updatedAuthentication) : false);
    };

    // Add event listener for storage changes
    window.addEventListener('storage', handleStorageChange);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []); // Empty dependency array means this effect runs once after the initial render

  return (
    <AuthContext.Provider value={{ isAdmin: isSuperAdmin, setIsAdmin: setIsSuperAdmin }}>

    <div>
    <div className="flex flex-col h-screen">
        <Header />
        <div className="flex flex-1">
        <Sidebar />
        <div className="flex-1 p-4">
      <Routes>
        {/* {authenticated ? ( */}
          {/* <> */}
            <Route path="/home" element={<Home />} />
            <Route path="/currentBids" element={<CurrentBids />} />
            <Route path="/bidsDetail" element={<BidsDetail />} />
            <Route path="/gameCategories" element={<GameCategories />} />
            <Route path="/addCategories" element={<AddCategories />} />
            <Route path="/addGames" element={<AddGames />} />
            <Route path="/games" element={<Games />} />
            <Route path="/transaction" element={<Transaction />} />
            <Route path="/transactionDetails" element={<TransactionDetails />} />
            <Route path="/gameBids" element={<GameBids />} />
            <Route path="/customers" element={<Customers />} />
            <Route path="/sendMessage" element={<SendMessage />} />
            <Route path="/broadcast" element={<BroadcastMessage />} />
            <Route path="/feedback" element={<Feedback />} />
            <Route path="/feedresponse" element={<FeedResponse />} />









            
          {/* </>
        ) : (
          <>
          <Route path="/" element="helo" />
          <Route path="*" element={<NotFound />} />
          </>
        )} */}
      </Routes>
        </div>
      </div>
    </div>
    </div>
    </AuthContext.Provider>

  );
};

export default App;
