import React, { useState,useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { GridComponent, ColumnsDirective, ColumnDirective } from '@syncfusion/ej2-react-grids';
import {  customertransactionGrid } from '../data/dummy';


const SendMessage = () => {
  const location = useLocation();
  const params = location.state;
  const navigateTo = useNavigate();

  const [message, setMessage] = useState('');
  const [gridData, setGridData] = useState([]);
  const toolbarOptions = ['ExcelExport'];
const BOT_TOKEN='6717614392:AAF1lKDz_uHkQskJ3ezhsbtMiRP2inE-tfI'
  const toolbarClick = (args) => {
    if (args.item.id === 'gridcomp_excelexport') {
      document.getElementById('gridcomp').ej2_instances[0].excelExport();
    } else if (args.item.id === 'gridcomp_pdfexport') {
      document.getElementById('gridcomp').ej2_instances[0].pdfExport();
    }
  };
  const handleSendMessage = () => {
    console.log(`Sending message to ${params.userid} (${params.phone}): ${message}`);
    axios.post(`https://api.telegram.org/bot${BOT_TOKEN}/sendMessage`, {
      chat_id: params.userid,
      text: message,
    })
      .then(response => {
        console.log('Private message sent successfully', response.data);
      })
      .catch(error => {
        console.error('Error sending private message:', error);
      });
      
    navigateTo('/customers');
    setMessage('');
  };


  const ImageTemplate = ({ Image }) => {
    const [imageUrl, setImageUrl] = useState('');

    
    useEffect(() => {
      fetch(`https://api.telegram.org/bot${BOT_TOKEN}/getFile?file_id=${Image}`)
        .then(response => response.json())
        .then(data => {
          const filePath = data.result.file_path;
          console.log(filePath)
          if (filePath==='null'){

              const imageUrl = `https://api.telegram.org/file/bot${BOT_TOKEN}/AgACAgQAAxkBAAIB_WWtJzxK6S0KX4TQHXrUkTThHUNFAAK2wDEbndJpUTBxVdCz9qJoAQADAgADbQADNAQ`;
              setImageUrl(imageUrl);
          }
          else{
            const imageUrl = `https://api.telegram.org/file/bot${BOT_TOKEN}/${filePath}`;
              setImageUrl(imageUrl);
          }
        })
        .catch(error => {
            setImageUrl('')
          console.error('Error fetching image data:', error);
        });
    }, [Image]);

    return <img src={imageUrl} alt="" width="180" className='h-20' />;
  };

  const showTransactionDetails = (args) => {
    const selectedRowData = args.data;
    console.log(selectedRowData)
    const imageUrl = selectedRowData.Image;
    const params = {

      image:imageUrl,
      status:selectedRowData.status,
      tid:selectedRowData.transaction_id,
      userid:selectedRowData.user_id,
      amount:selectedRowData.amount,
      type:selectedRowData.type,
      bank:selectedRowData.bank,

    };

    navigateTo('/transactionDetails', { state: params });
  };

  
  const handleActionComplete = (args) => {
    if (args.requestType === 'save') {
      const updatedData = args.data;
      console.log(args.data)

      const isConfirmed = window.confirm('Do you want to save changes?');

      if (isConfirmed) {
        axios
          .put(`https://gowinadminback.433sportethiopia.com/updateTransaction/${updatedData.transaction_id}`, updatedData)
          .then((response) => {
            console.log('Transaction updated:', response.data);
          })
          .catch((error) => {
            console.error('Error updating game:', error);
            alert('Error updating game. Please try again.'); // Notify the user about the error
          });
      }
    }
  };

  useEffect(() => {
    axios
      .get('https://gowinadminback.433sportethiopia.com/transactionspercustomer', {
        params: {
          userid: params.userid // Assuming params.userid contains the user ID
        }
      })
      .then((response) => {
        const formattedData = response.data.map((row) => {
          const date = new Date(row.date);
          const timeZoneOffsetInMinutes = date.getTimezoneOffset();
          const adjustedMatchDate = new Date(date.getTime() - timeZoneOffsetInMinutes * 60 * 1000);
  
          const formattedMatchDate = adjustedMatchDate.toISOString().split('T')[0];
          return {
            type: row.type,
            status: row.status,
            user: row.user_name,
            phonenum: row.user_phone,
            amount: row.amount,
            date: formattedMatchDate,
            transaction_id: row.transaction_id,
            user_id: row.user_id,
            bank: row.bank_name,
            accnum: row.accnum || '-',
            Image: row.screenshot || 'AgACAgQAAxkBAAIB_WWtJzxK6S0KX4TQHXrUkTThHUNFAAK2wDEbndJpUTBxVdCz9qJoAQADAgADbQADNAQ',
          };
        });
  
        setGridData(formattedData);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [params.userid]); // Add params.userid to the dependency array to re-fetch data when it changes
  

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Send Message to {params.name}</h1>

      <div className="mb-4">
        <label className="mr-2">Customer Name:</label>
        <span>{params.name}</span>
      </div>

      <div className="mb-4">
        <label className="mr-2">Customer Phone:</label>
        <span>{params.phone}</span>
      </div>

      <div className="mb-4">
        <label className="mr-2">Account Number:</label>
        <span>{params.accountnum}</span>
      </div>

      <div className="mb-4">
        <label className="mr-2">Balance:</label>
        <span>{params.balance}</span>
      </div>

      <div className="mb-4">
        <label className="mr-2">Message:</label>
        <textarea
          className="border p-2 w-full h-32"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Type your message here..."
        />
      </div>

      <button
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        onClick={handleSendMessage}
      >
        Send Message
      </button>

      <br/><br/><br/><br/><br/>
      <div className="mb-4">
        <label className="mr-2">Transaction History ({params.name}):</label>
   
      </div>
      {/* Grid Component */}
      <GridComponent dataSource={gridData}
        actionComplete={(args) => handleActionComplete(args)}
        allowPaging
        allowSorting
        allowResize
        allowFiltering
        editSettings={{  mode: 'Normal', showDeleteConfirmDialog: false }}
        pageSettings={{ pageSize: 20 }}
        allowExcelExport="true"
        toolbar={toolbarOptions}
        toolbarClick={toolbarClick}
        height="500"
        rowSelected={showTransactionDetails}
      >
        <ColumnsDirective>
          {customertransactionGrid.map(col => <ColumnDirective key={col.field} field={col.field} headerText={col.headerText} />)}
          <ColumnDirective headerText="Image" width="90" textAlign="Center" template={ImageTemplate} />

        </ColumnsDirective>
      </GridComponent>
    </div>
  );
};

export default SendMessage;
