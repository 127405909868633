import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

const Sidebar = () => {
  const [activeOption, setActiveOption] = useState(null);

  const handleOptionClick = (index) => {
    setActiveOption(index);
  };

  return (
    <aside className="bg-gray-800 text-white h-full w-1/5 p-4 md:p-6">
      <ul>
        <li className={`mb-4 ${activeOption === 2 ? 'bg-gray-600 text-gray-900 p-2 rounded-md' : ''}`}>
          <NavLink
            to="/gameCategories"
            className="text-gray-300 hover:text-white"
            activeClassName={`border-b-2 border-white rounded ${activeOption === 2 ? 'border-opacity-75' : 'border-opacity-0'}`}
            onClick={() => handleOptionClick(2)}
          >
            Game Categories
          </NavLink>
        </li>
        <li className={`mb-4 ${activeOption === 3 ? 'bg-gray-600 text-gray-900 p-2 rounded-md' : ''}`}>
          <NavLink
            to="/games"
            className="text-gray-300 hover:text-white"
            activeClassName={`border-b-2 border-white rounded ${activeOption === 3 ? 'border-opacity-75' : 'border-opacity-0'}`}
            onClick={() => handleOptionClick(3)}
          >
            Games
          </NavLink>
        </li>
        <li className={`mb-4 ${activeOption === 4 ? 'bg-gray-600 text-gray-900 p-2 rounded-md' : ''}`}>
          <NavLink
            to="/transaction"
            className="text-gray-300 hover:text-white"
            activeClassName={`border-b-2 border-white rounded ${activeOption === 4 ? 'border-opacity-75' : 'border-opacity-0'}`}
            onClick={() => handleOptionClick(4)}
          >
            Transaction
          </NavLink>
        </li>
        <li className={`mb-4 ${activeOption === 5 ? 'bg-gray-600 text-gray-900 p-2 rounded-md' : ''}`}>
          <NavLink
            to="/customers"
            className="text-gray-300 hover:text-white"
            activeClassName={`border-b-2 border-white rounded ${activeOption === 5 ? 'border-opacity-75' : 'border-opacity-0'}`}
            onClick={() => handleOptionClick(5)}
          >
            Customer
          </NavLink>
        </li>
        <li className={`mb-4 ${activeOption === 6 ? 'bg-gray-600 text-gray-900 p-2 rounded-md' : ''}`}>
          <NavLink
            to="/feedback"
            className="text-gray-300 hover:text-white"
            activeClassName={`border-b-2 border-white rounded ${activeOption === 6 ? 'border-opacity-75' : 'border-opacity-0'}`}
            onClick={() => handleOptionClick(6)}
          >
            Feedback
          </NavLink>
        </li>
      </ul>
    </aside>
  );
};

export default Sidebar;
